import { useCallback, useMemo } from 'react'
import {
  useNotify,
  useTranslate
} from 'react-admin'
import { capitalize } from '../util'

const useNotifyResponse = props => {
  const notify = useNotify(props)
  const { resource } = props
  const translate = useTranslate()
  const resourceName = useMemo(() => {
    return capitalize(translate(`resources.${resource}.name`, {
      smart_count: 1,
    }))
  }, [translate, resource])

  const notifySuccess = useCallback((action) => {
    notify(`ra.notification.${action}`, {
      type: 'info',
      messageArgs: {
        resource: resourceName,
      },
    })
  }, [notify, resourceName])

  const notifyDeleteSuccess = useCallback(() => {
    notifySuccess('deleted')
  }, [notifySuccess])

  const notifyFailure = useCallback((error) => {
    const { status, body } = error
    const { generalErrors } = body
    if (status === 422) {
      if (generalErrors?.length) {
        notify('ra.notification.http_validation_error_with_message', {
        type: 'warning',
        messageArgs: {
          resource: resourceName,
          message: generalErrors.join('\n'),
        },
        autoHideDuration: 100000,
        })
      } else {
        notify('ra.notification.http_validation_error', {
          type: 'warning',
          messageArgs: {
            resource: resourceName,
          },
        })
      }
    } else if (generalErrors?.length) {
      notify('ra.notification.message', {
        type: 'warning',
        messageArgs: {
          message: generalErrors.join('\n'),
        },
      })
    } else {
      notify('ra.notification.http_error', { type: 'error' })
    }
  }, [notify, resourceName])

  return {
    notifySuccess,
    notifyDeleteSuccess,
    notifyFailure,
  }
}

export default useNotifyResponse
