import resources from './resources'

const mapAttribute = (attribute) => {
  return Object.entries(resources).reduce((accum, [k, v]) => {
    const attributeValue = v[attribute]
    if (attributeValue) {
      return {
        ...accum,
        [k]: attributeValue,
      }
    } else {
      return accum
    }
  }, {})
}

const relationships = mapAttribute('relationships')
const resourceTypes = Object.keys(resources)
const humanReadableIdentifiers = mapAttribute('humanReadableIdentifier')
const dependsOnResources = mapAttribute('dependsOn')
const postParams = mapAttribute('postParams')
const sorts = mapAttribute('sort')
const optionTexts = mapAttribute('optionText')
const filterTransforms = mapAttribute('filterTransform')

export {
  resources,
  relationships,
  resourceTypes,
  humanReadableIdentifiers,
  dependsOnResources,
  postParams,
  sorts,
  optionTexts,
  filterTransforms,
}
