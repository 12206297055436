const materialGradeTypes = [
  'na',
  'commercial',
  'state',
]

const materialGradeDisplayNames = {
  commercial: 'COMM',
  state: 'STATE',
}

const materialGradeChoices = materialGradeTypes.map(id => ({
  id,
  name: `resources.tonnage_orders.material_grade_type.${id}`,
}))

const getMaterialAndGrade = (materialName, materialGradeKey) => {
  return [
    materialName,
    materialGradeDisplayNames[materialGradeKey] == null ? '' : ` (${materialGradeDisplayNames[materialGradeKey]})`,
  ].join('')
}

export {
  materialGradeChoices,
  getMaterialAndGrade,
}
