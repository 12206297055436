import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react'
import {
  TextInput,
  useRecordContext,
  useResourceContext,
  ReferenceInput,
  AutocompleteInput,
  useNotify,
  DataProviderContext,
  required,
  SelectInput,
} from 'react-admin'
import { Box } from '@mui/system'
import {
  Grid,
  Typography,
  CircularProgress,
  Link as ExternalLink,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { useFormContext, useWatch } from 'react-hook-form'
import { Form, NumberInput } from '../../custom'
import { useLabel, useTranslateResource, usePrevious } from '../../../hooks'
import { relationships } from '../../../data-model'
import DateTimePicker from '../../custom/date-time-picker'
import { materialGradeChoices, getMaterialAndGrade } from '../../shared/orders/util'

const currencyFieldOptions = {
  style: 'currency',
  currency: 'USD',
}

const formatAddress = (record) => {
  const { address_street_1, address_street_2, address_united_state_abbreviation, address_city, address_zip } = record
  return [
    address_street_1 ? `${address_street_1},` : null,
    address_street_2 ? `${address_street_2},` : null,
    address_city ? `${address_city},` : null,
    address_united_state_abbreviation,
    address_zip,
  ].filter(Boolean).join(' ')
}

const PinLink = ({ pinLink }) => {
  const translateSite = useTranslateResource('sites', 'fields')

  return (
    <ExternalLink target='_blank' tabIndex='-1' underline='none' variant='body2' href={pinLink}>
      {translateSite('google_maps_pin_link')}
    </ExternalLink>
  )
}

const RouteMap = ({ rate }) => {
  const translateSite = useTranslateResource('sites', 'fields')

  const routeMapSrc = useMemo(() => {
    const pickupLat = rate?.pickup_site?.lat
    const pickupLong = rate?.pickup_site?.long
    const dropoffLat = rate?.dropoff_site?.lat
    const dropoffLong = rate?.dropoff_site?.long
    if (![pickupLat, pickupLong, dropoffLat, dropoffLong].every(Boolean)) return null
    return `https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&origin=${pickupLat},${pickupLong}&destination=${dropoffLat},${dropoffLong}`
  }, [rate])

  if (!routeMapSrc) return null

  return (
    <Box sx={{ padding: 2 }}>
      <Box component='iframe'
        title={translateSite('route_link')}
        sx={{ border: 0, width: '100%', height: 600 }}
        loading='lazy'
        src={routeMapSrc}
      />
    </Box>
  )
}

const RateOptionText = opt => {
  const optionColSpacing = 0.6
  const subtext = [
    opt.pickup_site.name,
    opt.material.name,
  ].join(' / ')

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'baseline',
      flexWrap: 'wrap',
      marginLeft: -optionColSpacing,
      marginRight: -optionColSpacing,
    }}>
      <Box sx={{
        paddingLeft: optionColSpacing,
        paddingRight: optionColSpacing,
      }}>
        {opt.code}
      </Box>
      <Box sx={{
        typography: 'caption',
        paddingLeft: optionColSpacing,
        paddingRight: optionColSpacing,
      }}>
        {subtext}
      </Box>
    </Box>
  )
}

const tonnageRateFilter = {
  code_present: true,
}

const FormBody = ({ isDisabled, onRateIdChange, onMaterialGradeChange: propsOnMaterialGradeChange }) => {
  const resource = useResourceContext()
  const translate = useTranslateResource(resource, 'form.general')
  const label = useLabel({ resource })
  const { resetField } = useFormContext()
  const rateId = useWatch({ name: 'tonnage_rate.id' })
  const prevRateId = usePrevious(rateId)

  const resourceRelationships = relationships[resource]

  const onMaterialGradeChange = useCallback(e => {
    propsOnMaterialGradeChange(e.target.value)
  }, [propsOnMaterialGradeChange])

  const rateQuery = useCallback((search) => ({ code_start: search, view: 'detail' }), [])
  const rateMatchSuggestion = useCallback((text, opt) => opt.code ? opt.code.toLocaleLowerCase().startsWith(text.toLocaleLowerCase()) : false, [])
  const rateInputText = useCallback(opt => opt.code ?? '', [])

  useEffect(() => {
    // Reset material grade when rate changes
    if (rateId !== prevRateId) {
      resetField('material_grade')
      propsOnMaterialGradeChange(null)
    }
  }, [rateId, prevRateId, resetField, propsOnMaterialGradeChange])

  return (
    <Box>
      <Box>
        <ReferenceInput
          source='tonnage_rate.id'
          reference={resourceRelationships.tonnage_rate}
          filter={tonnageRateFilter}
        >
          <AutocompleteInput
            disabled={isDisabled}
            validate={[required()]}
            label={translate('lookupRate')}
            autoHighlight
            inputText={rateInputText}
            matchSuggestion={rateMatchSuggestion}
            optionText={RateOptionText}
            filterToQuery={rateQuery}
            onChange={onRateIdChange}
          />
        </ReferenceInput>
        <DateTimePicker
          isDisabled={isDisabled}
          source='load_at'
          label={label('load_at')}
          validate={[required()]}
        />
        <SelectInput
          disabled={isDisabled}
          source='material_grade'
          label={label('material_grade')}
          choices={materialGradeChoices}
          validate={[required()]}
          onChange={onMaterialGradeChange}
        />
        <TextInput
          disabled={isDisabled}
          source='order_number'
          validate={[required()]}
          label={label('order_number')}
        />
        <NumberInput
          disabled={isDisabled}
          source='num_trucks'
          validate={[required()]}
          label={label('num_trucks')}
        />
      </Box>
      {
        isDisabled &&
        <Box>
          <Grid container flexWrap='nowrap' display='inline-flex' columnSpacing={0.5} sx={{ marginTop: 3 }}>
            <Grid item display='flex'>
              <InfoIcon color='info' fontSize='small' />
            </Grid>
            <Grid item>
              <Typography variant='body2' color='text.secondary'>{translate('editMessage')}</Typography>
            </Grid>
          </Grid>
        </Box>
      }
    </Box>
  )
}

const SelectedRate = ({ isLoading, rate, materialGrade }) => {
  const resource = useResourceContext()
  const label = useLabel({ resource })
  const translateForm = useTranslateResource(resource, 'form.general')

  const items = useMemo(() => {
    if (!rate) return []
    return [
      {
        cols: 4,
        label: label('tonnage_rate.base_rate_in_dollars'),
        value:
          <>
            <Typography variant='body1'>{Intl.NumberFormat('en-US', currencyFieldOptions).format(rate.base_rate_in_dollars)}</Typography>
          </>
      },
      {
        cols: 4,
        label: label('tonnage_rate.fuel_surcharge_in_dollars'),
        value:
          <>
            <Typography variant='body1'>{Intl.NumberFormat('en-US', currencyFieldOptions).format(rate.fuel_surcharge_in_dollars)}</Typography>
          </>
      },
      {
        cols: 4,
        label: label('tonnage_rate.material_surcharge_in_dollars'),
        value:
          <>
            <Typography variant='body1'>{Intl.NumberFormat('en-US', currencyFieldOptions).format(rate.material_surcharge_in_dollars)}</Typography>
          </>
      },
      {
        cols: 6,
        label: label('tonnage_rate.recipient.name'),
        value:
          <>
            <Typography variant='body1'>{rate.recipient.name}</Typography>
          </>
      },
      {
        cols: 6,
        label: label('tonnage_rate.material.name'),
        value:
          <>
            <Typography variant='body1'>
              {getMaterialAndGrade(rate.material.name, materialGrade)}
            </Typography>
          </>
      },
      {
        cols: 6,
        label: label('tonnage_rate.dropoff_site.name'),
        value:
          <>
            <Typography variant='body1'>{rate.dropoff_site.name}</Typography>
            <Typography variant='body2'>{formatAddress(rate.dropoff_site)}</Typography>
            <PinLink pinLink={rate.dropoff_site.google_maps_pin_link} resource={resource} />
          </>
      },
      {
        cols: 6,
        label: label('tonnage_rate.pickup_site.name'),
        value:
          <>
            <Typography variant='body1'>{rate.pickup_site.name}</Typography>
            <Typography variant='body2'>{formatAddress(rate.pickup_site)}</Typography>
            <PinLink pinLink={rate.pickup_site.google_maps_pin_link} resource={resource} />
          </>
      },
    ]
  }, [rate, resource, label, materialGrade])

  return (
    <Box padding={4}>
      <Grid container sx={{ marginBottom: 2 }} spacing={1} alignItems='center'>
        <Grid item>
          <Typography>
            {label('tonnage_rate.id')}
          </Typography>
        </Grid>
        <Grid item>
          {
            isLoading ?
            <CircularProgress color='inherit' size={16} /> : (
              rate ?
              <Typography fontSize={'.9em'}>
                {rate.code ? `(${rate.code})` : `(${translateForm('rateWithoutCode')})`}
              </Typography> :
              null
            )
          }
        </Grid>
      </Grid>
      <Box style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        {
          rate ?
          <Grid container columnSpacing={2} rowSpacing={4}>
            {
              items.map((item, idx) => (
                <Grid item md={item.cols} xs={12} key={idx}>
                  <Typography variant='body2' sx={{
                    color: theme => theme.palette.grey['600'],
                    paddingBottom: 0.25,
                  }}>
                    {item.label}
                  </Typography>
                  {item.value}
                </Grid>
              ))
            }
          </Grid> :
          <Typography variant='body2'>
            {translateForm('noRate')}
          </Typography>
        }
      </Box>
    </Box>
  )
}

const TonnageOrdersForm = (props) => {
  const resource = useResourceContext()
  const record = useRecordContext()
  const [selectedRateId, setSelectedRateId] = useState(record?.tonnage_rate?.id)
  const [selectedMaterialGrade, setSelectedMaterialGrade] = useState(record?.material_grade)
  const [rate, setRate] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const dataProvider = useContext(DataProviderContext)

  const notify = useNotify()

  const getRate = useCallback(async () => {
    let data = null
    if (selectedRateId) {
      setIsLoading(true)
      try {
        const resp = await dataProvider.getOne('tonnage_rates', { id: selectedRateId })
        data = resp.data
      } catch(e) {
        notify(`resources.${resource}.form.general.rateFetchError`, { type: 'error' })
      }
    }
    setRate(data)
    setIsLoading(false)
  }, [notify, selectedRateId, resource, dataProvider])

  useEffect(() => {
    getRate()
  }, [getRate])

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item md={5}>
          <Form {...props}>
            <FormBody
              {...props}
              onRateIdChange={setSelectedRateId}
              onMaterialGradeChange={setSelectedMaterialGrade}
            />
          </Form>
        </Grid>
        <Grid item md={7}>
          <SelectedRate
            rate={rate}
            isLoading={isLoading}
            materialGrade={selectedMaterialGrade}
          />
        </Grid>
      </Grid>
      <RouteMap rate={rate} />
    </Box>
  )
}


export default TonnageOrdersForm
