import { useMemo } from 'react'
import {
  NumberField as RaNumberField
} from 'react-admin'
import get from 'lodash/get'
import set from 'lodash/set'

const options = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
}

const NumberField = ({ forceZero = false, record: propsRecord, options: propsOptions, ...rest }) => {
  const { source } = rest

  const record = useMemo(() => {
    if (forceZero && propsRecord) {
      const adjustedRecord = { ...propsRecord }
      const value = get(adjustedRecord, source)
      set(adjustedRecord, source, value ?? 0.00)
      return adjustedRecord
    } else {
      return propsRecord
    }
  }, [propsRecord, source, forceZero])

  return (
    <RaNumberField
      options={{ ...options, ...propsOptions }}
      {...rest}
      record={record}
    />
  )
}

NumberField.defaultProps = {
  textAlign: 'right',
}

export default NumberField
