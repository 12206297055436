import { useCallback } from 'react'
import {
  DeleteButton,
  Form as RaForm,
  SaveButton as RaSaveButton,
  useCreate,
  useRecordContext,
  useRedirect,
  useResourceContext,
  useUpdate,
} from 'react-admin'
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
import { useDeleteConfirmText, useNotifyResponse } from '../../hooks'
import { relationships } from '../../data-model'
import { useFormState } from 'react-hook-form'

const transformErrors = (resource, error) => {
  // Transform errors to format form can map to fields.
  // Handle nested resource errors (i.e. accepts_nested_attributes)
  const { body } = error
  const { attributeErrors = {} } = body

  const getErrorValue = (_resource, attr, errors) => {
    const joinedErrors = errors.join(', ').trim()
    return relationships[_resource]?.hasOwnProperty(attr) ? { id: joinedErrors } : joinedErrors
  }

  const attrRgx = /(?:(?<nestedResource>.+?)(?:\[(?<index>\d+)\])?\.)?(?<attr>.+)/
  return Object.entries(attributeErrors).reduce((accum, [_attr, errors]) => {
    const { nestedResource, index, attr } = _attr.match(attrRgx).groups
    const next = { ...accum }

    if (nestedResource) {
      if (index != null) {
        next[nestedResource] = [...(next[nestedResource] || [])]
        next[nestedResource][index] = {
          ...next[nestedResource][index],
          [attr]: getErrorValue(nestedResource, attr, errors)
        }
      } else {
        next[nestedResource] = { [attr]: getErrorValue(nestedResource, attr, errors) }
      }
    } else {
      next[attr] = getErrorValue(resource, attr, errors)
    }

    return next
  }, {})
}

const SaveButton = () => {
  const { isValid } = useFormState()
  return (
    <RaSaveButton
      disabled={!isValid}
    />
  )
}

const Form = ({ children, transform, hasDelete = true, isDisabled = false, ...rest }) => {
  const resource = useResourceContext()
  const record = useRecordContext()
  const deleteConfirmText = useDeleteConfirmText({ record, resource })
  const [update] = useUpdate()
  const [create] = useCreate()

  const redirect = useRedirect()
  const { notifySuccess, notifyFailure } = useNotifyResponse({ resource })
  const onSuccess = useCallback(() => {
    notifySuccess(record ? 'updated' : 'created')
    redirect('list', resource)
  }, [notifySuccess, redirect, record, resource])

  const onSubmit = useCallback(async rawData => {
    const data = transform ? transform(rawData) : rawData
    try {
      if (record) {
        await update(resource, { id: record.id, data, previousData: record }, { onSuccess, returnPromise: true })
      } else {
        await create(resource, { data }, { onSuccess, returnPromise: true })
      }
    } catch (error) {
      notifyFailure(error)
      return transformErrors(resource, error)
    }
  }, [update, create, resource, onSuccess, notifyFailure, record, transform])

  return (
    <Box
      sx={{
        padding: 4,
      }}
    >
      <RaForm
        {...rest}
        onSubmit={onSubmit}
        mode='onBlur'
      >
        <Box>
          {children}
        </Box>
        {
          (!isDisabled || hasDelete) &&
          <Grid
            container
            alignItems='center'
            marginTop={4}
            justifyContent='space-between'
          >
            {
              !isDisabled &&
              <Grid item >
                <SaveButton />
              </Grid>
            }
            {
              hasDelete &&
              <Grid item>
                <DeleteButton
                  confirmTitle={deleteConfirmText}
                />
              </Grid>
            }
          </Grid>
        }
      </RaForm>
    </Box>
  )
}

export default Form
