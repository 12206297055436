import { Login } from 'react-admin'
import { Grid } from '@mui/material'
import logo from '../../images/logo-light.png'

const LoginPage = () => {
  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      sx={{
        minHeight: '100vh',
        background: ({ palette }) => `linear-gradient(135deg, ${palette.secondary.main} 0%, ${palette.custom.gray.main} 300%)`,
      }}
    >
      <Grid
        item
        container
        direction='column'
        alignItems='center'
        sx={{
          marginTop: '10vh',
          marginBottom: 4,
        }}
      >
        <Grid item>
          <img height={180} src={logo} alt='logo' />
        </Grid>
        <Grid item>
          <Login
            sx={{
              background: 'none',
              minHeight: 0,
              height: 'auto',
              '& .MuiCardContent-root:last-child': {
                paddingBottom: 1,
              },
              '& .RaLoginForm-content': {
                padding: 1.5,
              },
              '& .RaLogin-card': {
                marginTop: 1.5,
                padding: 0,
                background: ({ palette }) => palette.custom.gray.main,
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LoginPage
