import { Empty as RaEmpty } from 'react-admin'
import { styled } from '@mui/material/styles'

const StyledEmpty = styled(RaEmpty)({
  '& .RaEmpty-icon': {
    height: '5em',
    width: '5em',
  },
  '& .MuiTypography-root': {
    fontSize: '1.5rem',
  },
})

const Empty = () => {
  return (
    <StyledEmpty
      hasCreate={false}
    />
  )
}

export default Empty
