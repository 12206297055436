const apiUrl = new URL(process.env.REACT_APP_API_ROOT)

const getUrl = (pathname, { path, query } = {}) => {
  let url = new URL(`${apiUrl.pathname}/${pathname}`, apiUrl.origin)
  if (path) {
    url = new URL(`${url.pathname}/${path}`, url)
  }
  if (query) {
    const search = new URLSearchParams()
    Object.entries(query).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        v.forEach(_v => {
          search.append(`${k}[]`, _v)
        })
      } else {
        search.append(k, v)
      }
    })
    url.search = search
  }
  return url
}

export default getUrl
