import { Box } from '@mui/system'
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar as RaToolbar,
  SaveButton,
  PasswordInput,
  useRedirect,
  minLength,
  required,
  FunctionField,
} from 'react-admin'
import { useLabel, useNotifyResponse } from '../../../hooks'

const Toolbar = props => (
  <RaToolbar {...props}>
    <SaveButton />
  </RaToolbar>
)

const resource = 'users'

const validatePassword = [required(), minLength(8)]

const UsersEdit = () => {
  const { notifySuccess, notifyFailure } = useNotifyResponse({ resource })
  const redirect = useRedirect()
  const label = useLabel({ resource })

  return (
    <Edit
      resource={resource}
      id={'0'}
      mutationMode='pessimistic'
      mutationOptions={{
        onSuccess: () => {
          notifySuccess('updated')
          redirect('/')
        },
        onError: (error) => {
          notifyFailure(error)
        }
      }}
    >
      <SimpleForm toolbar={<Toolbar />} sx={{ padding: theme => theme.spacing(4) }}>
        <Box sx={{ maxWidth: 500 }}>
          <TextInput source='email' label={label('email')} disabled />
          <FunctionField render={record => {
            return (
              !record.has_temp_password &&
              <PasswordInput source='old_password' validate={required()} label={label('old_password')} />
            )
          }} />
          <PasswordInput source='password' validate={validatePassword} label={label('new_password')} />
        </Box>
      </SimpleForm>
    </Edit>
  )
}

export default UsersEdit
