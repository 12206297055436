import { defaultTheme } from 'react-admin'
import merge from 'lodash/merge'
import palette from './palette'

const theme = merge({}, defaultTheme, {
  palette,
  sidebar: {
    width: 200,
    closedWidth: 48,
  },
  components: {
    MuiIconButton: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'secondary',
      },
    },
    RaFilterForm: {
      styleOverrides: {
        root: {
          alignItems: 'flex-start',
        },
      },
    },
    RaFilterFormInput: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'dense',
        fullWidth: true,
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          whiteSpace: 'pre-line',
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& .RaDatagrid-expandIconCell': {
            textAlign: 'center',
          },
        }
      }
    },
    RaLabeled: {
      styleOverrides: {
        root: {
          '& .RaLabeled-label': {
            fontSize: '0.80em',
          },
        },
      },
    },
  },
})

export default theme
