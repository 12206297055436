import {
  Pagination as RaPagination
} from 'react-admin'

const Pagination = () => {
  return (
    <RaPagination
      rowsPerPageOptions={[]}
      limit={null}
      sx={{
        '& .MuiTablePagination-toolbar': {
          '& .MuiTablePagination-actions': {
            marginLeft: ({ xs: 0.5, md: 2 }),
          },
          minHeight: 0,
          paddingLeft: { xs: 1, md: 2 },
        },
        '& .MuiTablePagination-displayedRows': {
          margin: 0,
        },
      }}
    />
  )
}

export default Pagination
