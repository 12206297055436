import { Children, cloneElement } from 'react'
import {
  Datagrid as RaDatagrid,
  useListContext,
} from 'react-admin'
import Empty from './empty'
import { useLabel } from '../../hooks'

const Datagrid = (props) => {
  const { children, ...rest } = props
  const { resource } = useListContext()
  const label = useLabel({ resource })

  return (
    <RaDatagrid
      {...rest}
      variant='dense'
      empty={<Empty resource={resource} />}
      bulkActionButtons={false}
      hover={Boolean(rest.rowClick)}
      expandSingle
      sx={{
        '& .MuiTableCell-head': {
          fontWeight: ({ typography }) => typography.fontWeightBold,
          padding: 2,
        },
        '& .MuiTableCell-body': {
          paddingY: 1,
        },
      }}
    >
      {
        Children.map(children, child => child.props.source ? cloneElement(child, {
          label: child.props.label ? label(child.props.label) : child.props.label === false ? false : label(child.props.source),
        }) : child)
      }
    </RaDatagrid>
  )
}

export default Datagrid
