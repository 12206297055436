import { AppBar as RaAppBar } from 'react-admin'
import UserMenu from './user-menu'
import {
  Typography,
  Box,
} from '@mui/material'

const AppBar = props => {
  return (
    <RaAppBar
      {...props}
      userMenu={<UserMenu />}
      container={Box} // override Slide effect - keep fixed
    >
      <Typography
        variant='h6'
        color='inherit'
        id='react-admin-title'
        sx={{
          margin: 0,
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textTransform: 'capitalize',
        }}
      />

    </RaAppBar>
  )
}

export default AppBar

