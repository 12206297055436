import makeRequestFn from './request'

const defaultOptions = {
  headers: {
    Accept: 'application/json; charset=utf-8',
    'Content-Type': 'application/json; charset=utf-8',
  },
}

const request = makeRequestFn(defaultOptions)

const jsonHttpClient = {
  get(url, options = {}) {
    return request(url, {
      method: 'GET',
      ...options,
    })
  },

  post(url, data, options = {}) {
    return request(url, {
      ...options,
      method: 'POST',
      body: JSON.stringify(data),
    })
  },

  patch(url, data, options = {}) {
    return request(url, {
      ...options,
      method: 'PATCH',
      body: JSON.stringify(data),
    })
  },

  delete(url, options = {}) {
    return request(url, {
      ...options,
      method: 'DELETE',
    })
  }
}

export default jsonHttpClient
