import {
  TextField,
  FunctionField,
} from 'react-admin'
import {
  Datagrid,
  List,
  DateTimeField,
  NumberField,
} from '../../custom'
import { getMaterialAndGrade } from '../../shared/orders/util'

const TonnageOrdersList = props => {
  return (
    <List
      {...props}
      hasCreate
    >
      <Datagrid
        rowClick='edit'
      >
        <DateTimeField source='load_at' />
        <TextField source='order_number' />
        <TextField source='tonnage_rate.code' sortBy='tonnage_rate_code' />
        <NumberField source='num_trucks' options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }} />
        <TextField source='tonnage_rate.recipient.name' sortBy='tonnage_rate_recipient_name' />
        <FunctionField source='tonnage_rate.material.name' sortBy='tonnage_rate_material_name' render={record => (
          getMaterialAndGrade(record.tonnage_rate.material.name, record.material_grade)
        )} />
        <TextField source='tonnage_rate.pickup_site.name' sortBy='tonnage_rate_pickup_site_name' />
        <TextField source='tonnage_rate.dropoff_site.name' sortBy='tonnage_rate_dropoff_site_name' />
      </Datagrid>
    </List>
  )
}

export default TonnageOrdersList
