import { NumberInput as RaNumberInput } from 'react-admin'

const onWheel = e => {
  if (e.target === document.activeElement) {
    e.target.blur()
    e.stopPropagation()
    window.requestAnimationFrame(() => {
      e.target.focus({ preventScroll: true })
    })
  }
}

const NumberInput = props => {
  return (
    <RaNumberInput
      {...props}
      onWheel={onWheel}
    />
  )
}

export default NumberInput
