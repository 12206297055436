// Order corresponds to order in side nav
// Not all resources appear in side nav

const resources = {
  tonnage_orders: {
    humanReadableIdentifier: 'order_number',
    sort: { field: 'load_at', order: 'ASC' },
    relationships: {
      tonnage_rate: 'tonnage_rates',
    },
    postParams: [
      'load_at',
      'num_trucks',
      'order_number',
      'tonnage_rate_id',
      'material_grade',
    ],
  },
  tonnage_rates: {
    humanReadableIdentifier: 'code',
    sort: { field: 'code', order: 'ASC' },
    relationships: {
      customer: 'customers',
    },
  },
  // order_uploads: {
  //   humanReadableIdentifier: 'image_filename',
  //   sort: { field: 'created_at', order: 'DESC' },
  // },
  users: {
  },
}

export default resources
