import {
  UserMenu as RaUserMenu,
  MenuItemLink,
  Logout,
  useTranslate,
} from 'react-admin'
import IconAccountSettings from '@mui/icons-material/AccountCircle'
import { capitalize } from '../../util'

const UserMenu = props => {
  const translate = useTranslate()

  return (
    <RaUserMenu
      {...props}
    >
      <MenuItemLink
        to='/account_settings'
        primaryText={capitalize(translate(`resources.users.name`, { smart_count: 1 }))}
        leftIcon={<IconAccountSettings />}
      />
      <Logout />
    </RaUserMenu>
  )
}

export default UserMenu
