import { useCallback, useMemo } from 'react'
import { useTranslate } from 'react-admin'

const useTranslateResource = (resource, namespace) => {
  const translate = useTranslate()
  const base = useMemo(() => {
    let str = `resources.${resource}`
    if (namespace) str += `.${namespace}`
    return str
  }, [resource, namespace])

  return useCallback((key, args) => {
    return translate(`${base}.${key}`, args)
  }, [base, translate])
}

export default useTranslateResource
