import Form from './form'
import { Edit } from '../../custom'

const TonnageOrdersEdit = () => {
  return (
    <Edit>
      <Form hasDelete={false} isDisabled />
    </Edit>
  )
}

export default TonnageOrdersEdit
