import { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import get from 'lodash/get'
import { humanReadableIdentifiers } from '../data-model'

const useDeleteConfirmText = ({ resource, record }) => {
  const translate = useTranslate()

  const deleteConfirmTitleText = useMemo(() => {
    const identifierValue = get(record, humanReadableIdentifiers[resource])

    const text = [
      translate('ra.action.delete'),
      translate(`resources.${resource}.name`, { smart_count: 1 }),
      identifierValue ? `"${identifierValue}"` : null,
    ].filter(Boolean).join(' ')

    return `${text}?`
  }, [resource, record, translate])

  return deleteConfirmTitleText
}

export default useDeleteConfirmText
