import { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { capitalize } from '../util'
import useTranslateResource from './use-translate-resource'

const useLabel = props => {
  const { resource } = props
  const translateResource = useTranslateResource(resource, 'fields')
  const translate = useTranslate()
  const getFieldTranslation = useCallback(field => {
    return translateResource(field)
  }, [translateResource])

  const getPrefixTranslation = useCallback(prefix => {
    return translate(`fieldPrefixes.${prefix}`)
  }, [translate])

  const getSuffixTranslation = useCallback(suffix => {
    return translate(`fieldSuffixes.${suffix}`)
  }, [translate])

  const label = useCallback((field, prefix, suffix) => {
    const str = [
      prefix ? getPrefixTranslation(prefix) : null,
      getFieldTranslation(field),
      suffix ? getSuffixTranslation(suffix) : null,
    ].filter(Boolean).join(' ')
    return capitalize(str)
  }, [getFieldTranslation, getPrefixTranslation, getSuffixTranslation])

  return label
}

export default useLabel
