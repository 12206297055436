import Form from './form'
import { Create } from '../../custom'

const TonnageOrdersCreate = () => {
  return (
    <Create>
      <Form />
    </Create>
  )
}

export default TonnageOrdersCreate
