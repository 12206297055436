// import orderUploadsComponents from './order-uploads'
import tonnageRatesComponents from './tonnage-rates'
import tonnageOrdersComponents from './tonnage-orders'

import { capitalize } from '../../util'
import camelCase from 'camelcase'
import { resourceTypes } from '../../data-model'

const componentsMap = {
  // orderUploadsComponents,
  tonnageRatesComponents,
  tonnageOrdersComponents,
}

const views = [
  'list',
  'create',
  'edit',
]

const mapComponents = module => {
  return views.reduce((accum, view) => {
    return {
      ...accum,
      [view]: module[capitalize(view)],
      options: {
        isMenuItem: true,
      },
    }
  }, {})
}

const adminResources = resourceTypes.map(name => {
  const module = componentsMap[`${camelCase(name)}Components`]

  return {
    name,
    ...(module ? mapComponents(module) : null),
  }
})

export default adminResources
