import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers'
import { TimeClock } from '@mui/x-date-pickers'
import { useInput } from 'react-admin'
import { useMemo, useState } from 'react'

const timeViews = ['hours', 'minutes', 'seconds']

const renderTimeViewClock = (props) => {
  const { views: propsViews, focusedView: propsFocusedView, openTo, closeOnSelect, viewRenderers, localeText, minDate, maxDate, yearsPerRow, onSelectedSectionsChange, ...rest } = props
  const views = propsViews.filter(v => timeViews.includes(v))
  const focusedView = propsFocusedView ? timeViews.find(v => propsFocusedView === v) : null
  return (
    <TimeClock
      {...rest}
      views={views}
      focusedView={focusedView}
    />
  )
}

const format = val => Date.parse(val) ? new Date(val) : null
const parse = val => Date.parse(val) ? val : null

const DateTimePicker = ({ source, defaultValue, validate, label, isDisabled }) => {
  const [pickerError, setPickerError] = useState()
  const input = useInput({ source, validate, defaultValue, format, parse })
  const { isRequired, field, fieldState } = input
  const { onBlur } = field
  const { error } = fieldState

  const errorMessage = useMemo(() => {
    if (error) {
      return error.message
    } else {
      switch (pickerError) {
        case 'maxDate':
        case 'minDate':
          return pickerError
        default:
          return null
      }
    }
  }, [error, pickerError])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDateTimePicker
        {...field}
        disabled={isDisabled}
        views={['day', 'hours', 'minutes']}
        onError={setPickerError}
        label={isRequired ? `${label} *` : label}
        slotProps={{
          textField: {
            onBlur,
            spellCheck: false,
            helperText: errorMessage ?? ' ',
            error: Boolean(errorMessage),
          },
          openPickerButton: {
            tabIndex: -1,
          }
        }}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
        }}
      />
    </LocalizationProvider>
  );
}

export default DateTimePicker
