import React from 'react'
import { Sidebar as RaSidebar } from 'react-admin'

const Sidebar = props => {
  return (
    <RaSidebar
      {...props}
      sx={{
        '&.RaSidebar-appBarCollapsed': {
          marginTop: 0,
        }
      }}
    />
  )
}

export default Sidebar
