import makeRequestFn from './request'

const defaultOptions = {
  headers: {
    Accept: 'application/json; charset=utf-8',
  },
}

const request = makeRequestFn(defaultOptions)

const createFormData = data => {
  return Object.entries(data).reduce((accum, [key, value]) => {
    accum.append(key, value ?? '')
    return accum
  }, new FormData())
}

const formDataHttpClient = {
  post(url, data, options = {}) {
    return request(url, {
      ...options,
      method: 'POST',
      body: createFormData(data),
    })
  },

  patch(url, data, options = {}) {
    return request(url, {
      ...options,
      method: 'PATCH',
      body: createFormData(data),
    })
  },
}

export default formDataHttpClient
