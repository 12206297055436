import { Create as RaCreate } from 'react-admin'

const Create = ({ children, ...rest }) => {
  return (
    <RaCreate
      mutationMode='pessimistic'
      {...rest}
    >
      {children}
    </RaCreate>
  )
}

export default Create
