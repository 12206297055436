const tonnageRateFields = {
  code: 'S/O #',
  base_rate_in_dollars: 'base rate',
  fuel_surcharge_in_dollars: 'fuel surcharge',
  material_surcharge_in_dollars: 'material surcharge',
  recipient: {
    name: 'recipient',
  },
  material: {
    name: 'material',
  },
  pickup_site: {
    name: 'pickup',
  },
  dropoff_site: {
    name: 'dropoff',
  },
}

const resources = {
  order_uploads: {
    name: 'order upload |||| order uploads',
    fields: {
      created_at: 'uploaded at',
      image_filename: 'filename',
    },
  },
  users: {
    name: 'account',
    fields: {
      email: 'email',
      new_password: 'new password',
      old_password: 'old password',
    },
  },
  sites: {
    fields: {
      google_maps_pin_link: 'google maps pin',
      route_link: 'google maps route link',
    },
  },
  tonnage_rates: {
    name: 'tonnage rate |||| tonnage rates',
    fields: tonnageRateFields,
  },
  tonnage_orders: {
    name: 'tonnage order |||| tonnage orders',
    fields: {
      load_at: 'load at',
      num_trucks: 'No. trucks',
      order_number: 'order #',
      status: 'status',
      material_grade: 'material grade',
      tonnage_rate: {
        id: 'rate',
        ...tonnageRateFields,
      },
    },
    material_grade_type: {
      na: 'N/A',
      commercial: 'COMMERCIAL',
      state: 'STATE',
    },
    form: {
      general: {
        lookupRate: `Rate (${tonnageRateFields.code})`,
        noRate: 'No rate selected',
        rateWithoutCode: `No ${tonnageRateFields.code}`,
        rateFetchError: 'Error finding rate details',
        editMessage: 'Please contact Haulz Logistics if you\'d like to edit this order',
      },
    },
  },
}

export default {
  resources,
}
