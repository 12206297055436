import { Layout as RaLayout } from 'react-admin'
import AppBar from './app-bar'
import Menu from './menu'
import Sidebar from './sidebar'

const Layout = props => {
  return (
    <RaLayout
      {...props}
      appBar={AppBar}
      menu={Menu}
      sidebar={Sidebar}
      sx={{
        '& .RaLayout-appFrame': {
          marginTop: 6,
        },
        '& .RaLayout-content': {
          paddingBottom: 2,
          paddingRight: { xs: 1, md: 2 },
        },
      }}
    />
  )
}

export default Layout
