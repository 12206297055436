import { Admin, Resource, CustomRoutes } from 'react-admin'
import { Route } from 'react-router-dom'
import { QueryClient } from 'react-query'
import CssBaseline from '@mui/material/CssBaseline'
import { createBrowserHistory } from 'history'
import jsonProvider from './json-provider'
import authProvider from './auth-provider'
import i18nProvider from './i18n-provider'
import theme from './theme'
import adminResources from './components/resources'
import Layout from './components/layout'
import LoginPage from './components/login'
import AccountEdit from './components/resources/users/edit'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const history = createBrowserHistory()
const dataProvider = jsonProvider()

function App() {
  return (
    <>
      <Admin
        disableTelemetry
        queryClient={queryClient}
        history={history}
        theme={theme}
        layout={Layout}
        loginPage={LoginPage}
        dataProvider={dataProvider}
        authProvider={authProvider()}
        i18nProvider={i18nProvider}
      >
        { adminResources.map((resourceProps, i) => <Resource key={i} {...resourceProps} />) }
        <CustomRoutes>
          <Route exact path='/account_settings' element={<AccountEdit/>} />
        </CustomRoutes>
      </Admin>
      <CssBaseline/>
    </>
  )
}

export default App
