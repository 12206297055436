import {
  TextField,
} from 'react-admin'
import {
  Datagrid,
  List,
  CurrencyField,
} from '../../custom'

const TonnageRatesList = props => {
  return (
    <List
      {...props}
    >
      <Datagrid>
        <TextField source='code' />
        <TextField source='recipient.name' sortBy='recipient_name' />
        <TextField source='material.name' sortBy='material_name' />
        <TextField source='pickup_site.name' sortBy='pickup_site_name' />
        <TextField source='dropoff_site.name' sortBy='dropoff_site_name' />
        <CurrencyField source='base_rate_in_dollars' />
        <CurrencyField source='fuel_surcharge_in_dollars' />
        <CurrencyField source='material_surcharge_in_dollars' />
      </Datagrid>
    </List>
  )
}

export default TonnageRatesList
