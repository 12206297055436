import {
  DateField as RaDateField
} from 'react-admin'

const options = {
  weekday: 'short',
  year:'numeric',
  month: 'numeric',
  day: '2-digit',
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short',
}

const DateTimeField = props => {
  return (
    <RaDateField
      options={options}
      {...props}
    />
  )
}

export default DateTimeField
