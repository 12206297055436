import { Edit as RaEdit } from 'react-admin'

const Edit = ({ children, ...rest }) => {
  return (
    <RaEdit
      mutationMode='pessimistic'
      {...rest}
    >
      {children}
    </RaEdit>
  )
}

export default Edit
