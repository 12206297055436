import NumberField from './number-field'

const options = {
  style: 'currency',
  currency: 'USD',
}

const CurrencyField = props => {
  const { options: propsOptions, ...rest} = props
  return (
    <NumberField
      options={{ ...options, ...propsOptions }}
      {...rest}
    />
  )
}

CurrencyField.defaultProps = NumberField.defaultProps

export default CurrencyField
