import pick from 'lodash/pick'
import { postParams, relationships, sorts as defaultSorts, filterTransforms } from '../data-model'

const getTotal = (json) => {
  return json.meta?.total ?? json.data.length
}

const getCollectionData = (json) => {
  return json.meta?.collection_data ?? {}
}

const getSortQuery = ({ sort }, resource) => {
  const defaultSort = defaultSorts[resource]
  const sorts = sort ? [sort] : []

  if (defaultSort && defaultSort.field && defaultSort.field !== sort?.field) {
    sorts.push(defaultSort)
  }

  return {
    sort: sorts.map(s => {
      const prefix = s.order === 'ASC' ? '' : '-'
      return `${prefix}${s.field}`
    }).join(',')
  }
}

const getPaginationQuery = ({ pagination = {}}) => {
  const { page, perPage } = pagination
  const result = {}
  if (page != null) result['page[number]'] = page
  if (perPage != null) result['page[size]'] = perPage
  return result
}

const getFilterQuery = ({ filter = {} }, resource) => {
  let cleanFilter = { ...filter }
  const filterTransform = filterTransforms[resource]

  if (filterTransform) {
    cleanFilter = filterTransform(cleanFilter)
  }

  return Object.entries(cleanFilter).reduce((accum, [key, val]) => {
    return {
      ...accum,
      [`filter[${key}]`]: val,
    }
  }, {})
}

const getPostParams = (resource, data) => {
  const transformed = { ...data }

  Object.keys(relationships[resource] || {}).forEach(relationship => {
    if (data.hasOwnProperty(relationship)) {
      transformed[`${relationship}_id`] = data[relationship]?.id
      delete transformed[relationship]
    }
  })

  if (postParams[resource]) {
    return pick(transformed, postParams[resource])
  } else {
    return transformed
  }
}

export {
  getTotal,
  getSortQuery,
  getPaginationQuery,
  getFilterQuery,
  getCollectionData,
  getPostParams,
}
